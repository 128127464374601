/* Main container elements */

header {
  text-align: center;
  min-width: 400px;
}

* {
  margin: 0 auto 0 auto;
  text-align: left;
}

p {
  text-align: center;
  font-size: 20px;
}
html,
body {
  text-align: center;
}

/* Nav elements */

a {
  font-weight: bold;
  color: #afdcc6;
  text-decoration: none;
}
a:hover {
  border-bottom: dotted 1px #659dbd;
}

/* Body elements */

body {
  margin: 0;
  padding: 0;
  text-align: left;
  font: Arial, Helvetica, sans-serif;
  font-size: 13px;
  background: #afdcc6;
}
#container {
  display: block;
  height: auto;
  position: relative;
  top: 30px;
  max-width: 800px;
  min-width: 365px;
  min-height: 1000px;
  background-color: #8d8741;
  background-repeat: repeat-y;
  padding-left: 17px;
  padding-right: 17px;
  overflow: hidden;
}

.welcometext {
  padding-top: 20px;
  font-family: "Slabo 27px", serif;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #8d8741;
}
.welcometext h2 {
  color: #afdcc6;
  text-align: center;
  top: 220px;
  font-size: 20px;
  margin-bottom: 20px;
}

.contenttext h2 {
  color: #123f83;
  text-align: center;
  top: 30px;
  font-size: 30px;
  margin-bottom: 30px;
}

.contenttext {
  background-color: #afdcc6;
  font-family: "Slabo 27px", serif;
  padding-top: 23px;
  padding-left: 10px;
  padding-right: 10px;
  height: 880px;
}

/* Dog pictures on About.js */

.dogs {
  text-align: center;
}
#dog1 {
  width: 30%;
  height: 53%;
}
#dog2 {
  width: 34%;
  height: 50%;
}
#dog3 {
  width: 30%;
  height: 50%;

/* Lost dog form on Submit.js */
}
#lostDog {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2em;
  height: auto;
  width: 70px;
  margin: auto;
  text-align: center;
}

#lostDog tbody {
  width: 100%;
}

#lostDog th {
  text-align: left;
}
#lostDog td,th {
  width: 100%;
  padding: .2em 1em;
}
.off {
  color: #0a2645;
}

/* Contact.js contact form */

.formtext h3 {
  color: #afdcc6;
  font-family: "Slabo 27px", serif;
  font-weight: 90;
  text-align: center;
  font-size: 15px;
}

.form-group {
  text-align: center;
}
.form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 1em;
}
.inputWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
input[type="text"]:focus {
  border: 3px solid #555;
}
table input[type="text"] {
  display: block;
  margin: 0 auto;
}
.entry {
  text-align: center;
}

/* Dog image on Home.js */



/* Footer element */

footer {
  font-size: 12px;
  margin-top: 30px;
  height: 35px;
  min-width: 375px;
  text-align: left;
  font-family: "Slabo 27px", serif;
}
#footer a,
#footer a:visited {
  text-align: center;
  color: #adccf1;
  text-decoration: none;
}

/* Google maps on Contact.js */

.maps {
  overflow: hidden;
  padding-bottom: 70.25%;
  text-align: center;
  position: relative;
  height: 0;
}
.map iframe {
  left: 0;
  top: 0;
  height: 150%;
  width: 100%;
  position: absolute;
}

/* if browser window is mobile or tablet */
/**** For Smaller screens ******/

@media (max-width: 549px) {
  .hidden--mobile {
    display: none !important;
  }
  nav {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 400px;
    font-family: "Slabo 15px", serif;
    background-color: #8d8741;
  }
  header {
    height: 11vh;
  }
  nav > i {
    padding: 0.4em;
  }
  nav > ul {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 0;
    font-size: 2em;
  }

  nav > ul > li {
    padding: 0.2rem;
    list-style-type: none;
  }
}
#mainlogo {
  width: 50%;
  height: 50%;
}

/***** For Bigger screens *****/

@media (min-width: 550px) {
  header {
    height: 19vh;
  }
  nav {
    background-color: #8d8741;
  }
  nav > ul {
    display: flex;
    justify-content: space-around;
    padding: 0 30rem;
  }

  nav > ul > li {
    display: inline-flex;
    text-decoration: none;
    padding: 0.9rem 4rem;
    font-size: 20px;
    font-family: "Slabo 27px", serif;
  }

  nav > .fa-bars {
    display: none;
  }
#mainlogo {
  width: 37%;
  min-width: 30%;
  height: 37%;
  min-height: 30%;
}
}

